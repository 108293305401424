<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Empty layout -->
<!-- ----------------------------------------------------------------------------------------------------- -->
@if (layout === 'empty') {
    <empty-layout></empty-layout>
}

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Minimal layout -->
<!-- ----------------------------------------------------------------------------------------------------- -->
@if (layout === 'minimal') {
    <minimal-layout></minimal-layout>
}

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Layouts with vertical navigation -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- Futuristic -->
@if (layout === 'futuristic') {
    <futuristic-layout></futuristic-layout>
}

<!-- Dense -->
@if (layout === 'dense') {
    <dense-layout></dense-layout>
}
