<fuse-loading-bar></fuse-loading-bar>
<fuse-vertical-navigation
    class="dark bg-primary-800 text-white print:hidden"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.futuristic"
    [opened]="!isScreenSmall"
    [footer]="footer.futuristic">
    <ng-container fuseVerticalNavigationHeader>
        <div class="flex h-20 items-center p-6 pb-0">
            <img
                class="w-30"
                src="assets/images/logo/payemoji-white.svg"
                alt="Payemoji logo" />
        </div>
    </ng-container>
    <ng-container fuseVerticalNavigationFooter>
        <div class="bg-card relative z-49 flex w-full flex-0 items-center justify-center px-4 pb-2 dark:bg-transparent md:px-6 print:hidden">
            <span
                class="text-xs font-normal text-white opacity-80"
                title="Message My Customer"
                >&copy; {{ currentYear }}</span
            >
        </div>
    </ng-container>
</fuse-vertical-navigation>
<div class="flex w-full min-w-0 flex-auto flex-col">
    <div class="bg-card relative z-49 flex h-16 w-full flex-0 items-center px-4 shadow dark:border-b dark:bg-transparent dark:shadow-none md:px-6 print:hidden">
        <button
            class="mr-2"
            mat-icon-button
            (click)="toggleNavigation('mainNavigation')">
            <mat-icon>
                <fa-icon [icon]="['far', 'bars']"></fa-icon>
            </mat-icon>
        </button>
        <!-- Components -->
        <div class="ml-auto flex items-center space-x-0.5 pl-2 sm:space-x-2">
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-auto flex-col">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
        Otherwise, layout changes won't be registered and the view won't be updated! -->
        @if (true) {
            <router-outlet></router-outlet>
        }
    </div>
</div>
