import { ResourceConfig, Attribute, ResourceProps, Relationship, Resource } from 'angular-jsonapi';
import { ResourceTypeDialogue } from 'app/shared/resource/resource';
import { DialogueStatus } from '../modules/chat/chat.types';

@ResourceConfig({
    type: ResourceTypeDialogue,
})
export class Dialogue extends Resource {
    @Attribute()
    status: DialogueStatus;

    @Relationship({ type: Resource })
    private agent: Resource;

    @Relationship({ type: Resource })
    private recipient: Resource;

    constructor(props: Partial<DialogueProps>) {
        super(props);
        if (props) {
            this.status = props.status;

            if (props.agent) {
                this.agent = new Resource(props.agent);
            }

            if (props.recipient) {
                this.recipient = new Resource(props.recipient);
            }
        }
    }

    Agent(): Resource {
        return this.agent;
    }

    Recipient(): Resource {
        return this.recipient;
    }
}

export type DialogueProps = Partial<ResourceProps> & {
    status: DialogueStatus;
    agent: Partial<ResourceProps>;
    recipient: Partial<ResourceProps>;
};
