<!-- Button -->
<button
    mat-icon-button
    [matMenuTriggerFor]="userActions">
    <span class="relative">
        <img
            class="h-7 w-7 rounded-full"
            src="assets/images/avatars/user-profile.svg"
            alt="User avatar" />
    </span>
</button>

<mat-menu
    [xPosition]="'before'"
    #userActions="matMenu">
    <button
        mat-menu-item
        (click)="signOut()">
        <mat-icon>
            <fa-icon
                class="fa-xs"
                [icon]="['far', 'arrow-right-from-bracket']"></fa-icon>
        </mat-icon>
        <span>Sign out</span>
    </button>
</mat-menu>
