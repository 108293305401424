import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { MenuRoute } from 'app/core/navigation/navigation-menu';
import { of, switchMap } from 'rxjs';

export const NoAuthGuard: CanActivateFn | CanActivateChildFn = (_route, _state) => {
    const router: Router = inject(Router);
    const authService = inject(AuthService);

    return authService.check().pipe(
        switchMap(authenticated => {
            if (authenticated) {
                return of(router.createUrlTree([MenuRoute.CHAT]));
            }
            return of(true);
        })
    );
};
