import { ActivatedRouteSnapshot, CanDeactivateFn, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

export interface IDeactivateComponent {
    canExit: (goTo: string) => Observable<boolean> | Promise<boolean> | boolean;
}

export const DeactivateGuard: CanDeactivateFn<IDeactivateComponent> = (
    component: IDeactivateComponent,
    _currentRoute: ActivatedRouteSnapshot,
    _currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
): Observable<boolean> | Promise<boolean> | boolean => {
    return component?.canExit ? component?.canExit(nextState.url) : true;
};
