import { Attribute, ResourceConfig, ResourceProps, Resource, Relationship, WrappedResource, Wrapped, NestedAttribute } from 'angular-jsonapi';
import { DialogueAgentOwner, DialogueAgentStatus, DialogueAgentVisibility } from 'app/modules/dialogue-agent/dialogue-agent.types';
import { ResourceTypeDialogueAgent } from 'app/shared/resource/resource';
import { Integration } from './integration.model';
import { AccountPermission } from './account-permission.model';

export class Webhook extends WrappedResource {
    @Wrapped()
    url: string;

    @Wrapped()
    status: DialogueAgentStatus;

    @Wrapped()
    events: string[];

    constructor(props: Partial<WebhookProps>) {
        super(props);
    }
}
@ResourceConfig({
    type: ResourceTypeDialogueAgent,
})
export class DialogueAgent extends Resource {
    @Attribute()
    name: string;

    @Attribute()
    owner: DialogueAgentOwner;

    @Attribute()
    status: DialogueAgentStatus;

    @Attribute()
    chatVisibility: DialogueAgentVisibility;

    @NestedAttribute({ type: Webhook })
    webhooks: Webhook[];

    @Relationship({ type: Resource })
    private channelGroup: Resource;

    @Relationship({ type: Integration })
    private integration: Integration;

    @Relationship({ type: AccountPermission })
    private dialoguePermissions: AccountPermission[];

    permissions: string[];

    constructor(props: Partial<DialogueAgentProps>) {
        super(props);
        if (props) {
            this.name = props.name;
            this.owner = props.owner;
            this.status = props.status;
            this.chatVisibility = props.chatVisibility;
            this.webhooks = props.webhooks;

            if (props.channelGroup) {
                this.channelGroup = new Resource(props.channelGroup);
            }
            if (props.integration) {
                this.integration = new Integration(props.integration);
            }
            if (props.dialoguePermissions) {
                this.dialoguePermissions = [];
                for (const dialoguePermission of props.dialoguePermissions) {
                    this.dialoguePermissions = [...this.dialoguePermissions, new AccountPermission(dialoguePermission)];
                }
            }
        }
    }

    ChannelGroup(): Resource {
        return this.channelGroup;
    }

    Integration(): Integration {
        return this.integration;
    }

    DialoguePermissions(): AccountPermission[] {
        return this.dialoguePermissions;
    }

    getPermissions(humanAgent: DialogueAgent): void {
        this.permissions = [];
        const permissions = humanAgent?.DialoguePermissions();
        permissions?.forEach((data: AccountPermission) => {
            const permissionId = data.Permission()?.id;
            const hasPermission = data.Targets()?.some((agent: Resource) => agent.id === this.id);
            if (hasPermission) {
                this.permissions.push(permissionId);
            }
        });
    }
}

export type DialogueAgentProps = Partial<ResourceProps> & {
    name: string;
    owner: DialogueAgentOwner;
    status: DialogueAgentStatus;
    chatVisibility: DialogueAgentVisibility;
    webhooks: Webhook[];
    channelGroup: Partial<ResourceProps>;
    integration: Partial<Integration>;
    dialoguePermissions: Partial<AccountPermission>[];
};

export type WebhookProps = {
    url: string;
    status: DialogueAgentStatus;
    events: string[];
};
