import { ResourceConfig, Attribute, ResourceProps, Relationship, Resource } from 'angular-jsonapi';
import { ResourceTypeIntegration } from 'app/shared/resource/resource';
import { ResourceWithOrganisation } from './resource-with-organisation.model';

@ResourceConfig({
    type: ResourceTypeIntegration,
})
export class Integration extends ResourceWithOrganisation {
    @Attribute()
    name: string;

    @Relationship({ type: Resource })
    private account: Resource;

    constructor(props: Partial<IntegrationProps>) {
        super(props);
        if (props) {
            this.name = props.name;
        }
    }

    Account(): Resource {
        return this.account;
    }
}

export type IntegrationProps = Partial<ResourceProps> & {
    name: string;
};
