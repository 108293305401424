import { ResourceConfig, Attribute, ResourceProps, Relationship, Resource, NestedAttribute, WrappedResource, Wrapped } from 'angular-jsonapi';
import { ResourceTypeMessage } from 'app/shared/resource/resource';
import { ChannelKind } from 'app/modules/channel/channel.types';
import { DeliveryErrorType, MessageDirection, MessageDisplayTypes } from 'app/modules/chat/chat.types';

export class MessageContent extends WrappedResource {
    @Wrapped()
    displayType: MessageDisplayTypes;

    @Wrapped()
    attrs: any;

    constructor(props: Partial<MessageContentProps>) {
        super(props);
    }
}

export class DeliveryStatuses extends WrappedResource {
    @Wrapped()
    sentAt: Date;

    @Wrapped()
    receivedAt: Date;

    @Wrapped()
    deliveredAt: Date;

    @Wrapped()
    readAt: Date;

    @Wrapped()
    abortedAt: Date;

    @Wrapped()
    deletedByRecipientAt: Date;

    constructor(props: Partial<DeliveryStatusesProps>) {
        super(props);
    }
}

export class DeliveryErrors extends WrappedResource {
    @Wrapped()
    time: Date;

    @Wrapped()
    description: string;

    @Wrapped()
    type: DeliveryErrorType;

    constructor(props: Partial<DeliveryErrorsProps>) {
        super(props);
    }
}

@ResourceConfig({
    type: ResourceTypeMessage,
})
export class Message extends Resource {
    @Attribute()
    channelType: ChannelKind;

    @NestedAttribute({ type: MessageContent, serializedName: 'content' })
    content: MessageContent[];

    @Attribute()
    context: any;

    @Attribute()
    direction: MessageDirection;

    @NestedAttribute({ type: DeliveryStatuses })
    deliveryStatuses: DeliveryStatuses;

    @NestedAttribute({ type: DeliveryErrors })
    deliveryErrors: DeliveryErrors[];

    @Attribute()
    locale: string;

    @Relationship({ type: Resource })
    private dialogue: Resource;

    @Relationship({ type: Resource })
    private templates: Resource[];

    constructor(props: Partial<MessageProps>) {
        super(props);
        if (props) {
            this.channelType = props.channelType;
            this.content = props.content;
            this.direction = props.direction;
            this.deliveryStatuses = props.deliveryStatuses;
            this.deliveryErrors = props.deliveryErrors;
            this.locale = props.locale;
            this.context = props.context;

            if (props.dialogue) {
                this.dialogue = new Resource(props.dialogue);
            }

            if (props.templates) {
                this.templates = [];
                props.templates.forEach(template => {
                    this.templates = [...this.templates, new Resource(template)];
                });
            }
        }
    }

    Dialogue(): Resource {
        return this.dialogue;
    }

    Templates(): Resource[] {
        return this.templates;
    }

    withTemplates(templates: Resource[]): void {
        this.templates = templates;
    }
}

export type MessageProps = Partial<ResourceProps> & {
    channelType: ChannelKind;
    content: MessageContent[];
    context: any;
    direction: MessageDirection;
    deliveryStatuses: DeliveryStatuses;
    deliveryErrors: DeliveryErrors[];
    locale: string;
    dialogue: Partial<ResourceProps>;
    templates: Partial<ResourceProps>[];
};

export type MessageContentProps = {
    displayType: MessageDisplayTypes;
    attrs: any;
};

export type DeliveryStatusesProps = {
    sentAt: Date;
    deliveredAt: Date;
    readAt: Date;
    abortedAt: Date;
    deletedByRecipientAt: Date;
};

export type DeliveryErrorsProps = {
    time: Date;
    description: string;
    type: DeliveryErrorType;
};
