export interface AclRecord extends JsonApiAttributes {
    id: string;
    relationships: {
        account?: JsonApiRelationship;
        permission: JsonApiRelationship;
        targets: JsonApiRelationships;
    };
}

export interface IRole {
    id: Role;
    type: string;
    attributes: {
        name: string;
        description: string;
    };
    relationships: {
        subroles: JsonApiRelationships;
        permissions: JsonApiRelationships;
    };
}

export class Account {
    id?: string;
    roles: AccountRole[];

    constructor(aclRecord: AclRecord[], included: any[] = []) {
        this.id = aclRecord.map(acl => acl.id).reduce((pre, cur) => `${pre}-${cur}`);
        this.roles = included
            ?.filter(i => i.type === 'roles')
            .map((iRole: IRole, index: number) => {
                const targets = aclRecord[index]?.relationships?.targets?.data.filter(t => t.type === iRole.id.split('.')[0]);
                return new AccountRole(iRole, targets);
            });
    }
}

export class AccountRole {
    id?: Role;
    name?: string;
    description: string;
    targets: string[];

    constructor(iRole: IRole, targets: Resource[] = []) {
        this.id = iRole.id;
        this.name = iRole.attributes?.name;
        this.description = iRole.attributes?.description;
        this.targets = targets?.map(t => t.id);
    }
}

export enum Role {
    SUPERADMIN = 'superadmin',
    ORG_SUPERADMIN = 'organisations.superadmin',
    ORG_ADMIN = 'organisations.admin',
}
