import { enableProdMode, ErrorHandler, importProvidersFrom } from '@angular/core';
import { environment } from 'environments/environment';
import { AppComponent } from './app/app.component';
import { CoreModule } from 'app/core/core.module';
import { AppRoutingModule } from './app/app-routing.module';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { ExceptionService } from './app/core/service/exception.service';
import { LoggingService } from './app/core/service/logging.service';

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, AppRoutingModule, CoreModule),
        LoggingService,
        { provide: ErrorHandler, useClass: ExceptionService },
        appConfig.providers,
        provideAnimations(),
    ],
}).catch(err => console.error(err));
