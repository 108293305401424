import { FuseConfirmationConfig } from '@fuse/services/confirmation';
import { ChannelKind } from '../channel/channel.types';
import { InternalDialogueAgent } from './chat.types';
import { MonoTypeOperatorFunction, Observable, defer, finalize, tap } from 'rxjs';
import { DialogueAgentStatus, DialogueAgentVisibility } from '../dialogue-agent/dialogue-agent.types';
import { DialogueAgent } from 'app/models/dialogue-agent.model';

export const LIMIT = 30;
export const OFFSET = 0;
export const DISTANCE_FROM_BOTTOM = -50;
export const DISTANCE_FROM_TOP = 750;

export const isToday = (dateString: string): boolean => {
    const today = new Date();
    const date = new Date(dateString);
    return today.getDate() === date.getDate() && today.getMonth() === date.getMonth() && today.getFullYear() === date.getFullYear();
};

export const getAgentIcon = (agentName: string): string => {
    switch (agentName) {
        case InternalDialogueAgent.AGENT:
            return 'user-headset';
        case InternalDialogueAgent.CHATBOT:
            return 'message-bot';
        default:
            return 'brain-circuit';
    }
};

export const getChannelClass = (kind: ChannelKind): string => {
    switch (kind) {
        case ChannelKind.WHATSAPP:
            return 'icon-whatsapp';
        default:
            return 'text-black';
    }
};

export const getChannelIcon = (kind: ChannelKind): string[] => {
    switch (kind) {
        case ChannelKind.WHATSAPP:
            return ['fab', 'whatsapp'];
        case ChannelKind.SMS:
            return ['fas', 'comment-sms'];
    }
};

export const confirmationDialogConfig: FuseConfirmationConfig = {
    title: '',
    icon: {
        show: false,
    },
    actions: {
        confirm: {
            show: true,
            label: 'OK',
            color: 'accent',
        },
        cancel: {
            show: false,
        },
    },
    dismissible: false,
};

export const isDialogueAgentByName = (agent: any, agentName: string): boolean => {
    return getAgentName(agent) === agentName;
};

export const getAgentName = (agent: DialogueAgent): string => {
    return agent?.name;
};

export const getDialogueAgentsVisible = (agents: DialogueAgent[]): DialogueAgent[] => {
    return agents.filter(agent => agent.status === DialogueAgentStatus.ENABLED && agent.chatVisibility === DialogueAgentVisibility.VISIBLE);
};

type CallbackFunc = () => void;

export const finalizeWithoutUnsubscription =
    <T>(callback: CallbackFunc): MonoTypeOperatorFunction<T> =>
    (source: Observable<T>) =>
        defer(() => {
            let completed = false;
            let errored = false;

            return source.pipe(
                tap({
                    error: () => (errored = true),
                    complete: () => (completed = true),
                }),
                finalize(() => {
                    if (errored) {
                        callback();
                    } else if (completed) {
                        callback();
                    }
                })
            );
        });

export const TERMINATE_DIALOGUE_CONFIRMATION = (agentName: string): string =>
    // eslint-disable-next-line max-len
    `The selected recipient and template type requires the termination of an active conversation with the ${agentName} in order to create a new agent conversation.<br/><br/>Do you wish to continue?`;

export const NOTIFICATION_ONBOARDING_CONFIRMATION = (agentName: string): string =>
    // eslint-disable-next-line max-len
    `The selected recipient is engaged in an active conversation with the ${agentName}. This notification will be injected into the message stream and may be out of context for the recipient.<br/><br/>Do you wish to continue?`;
