<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
    #fuseVerticalNav
    class="dark bg-primary-800 print:hidden"
    [appearance]="navigationAppearance"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [footer]="footer.default"
    [opened]="!isScreenSmall"
    (appearanceChanged)="aparenceChanged($event)">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeaderExtended>
        <!-- Logo -->
        <div class="flex h-20 items-center justify-center">
            <img
                class="w-30"
                src="assets/images/logo/payemoji-white.svg"
                alt="Payemoji logo" />
        </div>
    </ng-container>
    <ng-container fuseVerticalNavigationContentHeaderCollapsed>
        <!-- Logo -->
        <div class="flex h-20 items-center justify-center">
            <img
                class="w-8"
                src="assets\images\favicon\favicon-32x32.png"
                alt="Payemoji logo" />
        </div>
    </ng-container>
    <ng-container fuseVerticalNavigationFooter>
        <div class="bg-card relative z-49 flex w-full flex-0 items-center justify-center px-4 pb-2 dark:bg-transparent md:px-5 print:hidden">
            <span
                class="cursor-default text-xs font-normal text-white opacity-80"
                matTooltip="Message My Customer"
                matTooltipShowDelay="400"
                >&copy;
                {{ currentYear }}
            </span>
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex w-full min-w-0 flex-auto flex-col">
    <!-- Header -->
    <div class="bg-card relative z-49 flex h-16 w-full flex-0 items-center px-4 shadow dark:border-b dark:bg-transparent dark:shadow-none md:px-6 print:hidden">
        <div class="flex items-center space-x-2 pr-2">
            <!-- Navigation toggle button -->
            <button
                mat-icon-button
                (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
            </button>
            <!-- Navigation appearance toggle button -->
            @if (fuseVerticalNav.opened) {
                <button
                    class="hidden md:inline-flex"
                    mat-icon-button
                    (click)="toggleNavigationAppearance()">
                    <mat-icon [svgIcon]="'heroicons_outline:switch-horizontal'"></mat-icon>
                </button>
            }
        </div>
        <!-- Components -->
        <div class="ml-auto flex items-center space-x-0.5 pl-2 sm:space-x-2">
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-auto flex-col">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
        Otherwise, layout changes won't be registered and the view won't be updated! -->
        @if (true) {
            <router-outlet></router-outlet>
        }
    </div>
</div>
