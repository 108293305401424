import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Params, Router, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { OrganisationService } from 'app/modules/organisation/organisation.service';
import { QueryParamsService } from 'app/core/service/query-params.service';
import { Path } from 'app/core/navigation/navigation-menu';
import { Role } from 'app/shared/types/account.types';

export const AuthGuard: CanActivateFn | CanActivateChildFn = (route, _state) => {
    const router: Router = inject(Router);
    const authService = inject(AuthService);
    const organisationService = inject(OrganisationService);
    const queryParamsService = inject(QueryParamsService);

    const redirectUrl = (path: string[]): UrlTree => {
        const queryParams = queryParamsService.getSavedQueryParams(path);
        queryParamsService.deleteSavedQueryParams();
        if (queryParams) {
            return router.createUrlTree(path, { queryParams });
        }
        return null;
    };

    const getQueryParams = (path: string[]): Params => {
        const queryParams = queryParamsService.getQueryParams(path);
        if (!queryParams) {
            queryParamsService.deleteSavedQueryParams();
        }
        return queryParams;
    };

    const checkPermissions = (): Observable<boolean | UrlTree> => {
        const roles = route.data.roles as Array<Role>;
        if (!roles) {
            return of(true);
        } else if (authService.hasPermissions(roles)) {
            return of(true);
        }
        return of(router.createUrlTree([Path.FORBIDDEN]));
    };

    const segments = route.url;
    // Check token in url and set it
    const path = segments.map(segment => segment.path);
    // Check the authentication status
    return authService.check().pipe(
        switchMap(authenticated => {
            if (!authenticated) {
                queryParamsService.saveUrlQueryParams(path);
                return of(router.createUrlTree([Path.LOGIN]));
            }

            return authService.hasRole().pipe(
                switchMap(() => {
                    const queryParams = getQueryParams(path);

                    return organisationService.checkActiveOrganisation(queryParams?.orgID).pipe(
                        switchMap((hasActive: boolean) => {
                            if (!hasActive) {
                                return of(router.createUrlTree([Path.ORGANISATIONS]));
                            } else {
                                if (!path.includes(Path.FORBIDDEN)) {
                                    const infoOrganisations = organisationService.getInfoListOrganisation();
                                    if (path.includes(Path.ORGANISATIONS) && infoOrganisations?.totalCount === 1) {
                                        return of(router.createUrlTree([Path.CHAT]));
                                    }
                                    const redirect = redirectUrl(path);
                                    if (redirect) {
                                        return of(redirect);
                                    }
                                    return checkPermissions();
                                }
                                return of(true);
                            }
                        })
                    );
                })
            );
        })
    );
};
