import { ResourceConfig, Resource, ResourceProps, Attribute } from 'angular-jsonapi';
import { ResourceTypeOrganisation } from 'app/shared/resource/resource';
import { Centre, CentreProps } from './centre.model';
@ResourceConfig({
    type: ResourceTypeOrganisation,
})
export class Organisation extends Resource {
    @Attribute()
    tradeName: string;

    @Attribute()
    legalName: string;

    @Attribute()
    onlineStoreWebsites: string;

    @Attribute()
    centres: Centre[];

    constructor(data: Partial<OrganisationProps>) {
        super(data);
        if (data) {
            this.tradeName = data.tradeName;
            this.legalName = data.legalName;
            this.onlineStoreWebsites = data.onlineStoreWebsites;
            if (data.centres) {
                this.centres = data.centres.map(centre => new Centre(centre));
            }
        }
    }
}

export type OrganisationProps = Partial<ResourceProps> & {
    tradeName: string;
    legalName: string;
    onlineStoreWebsites: string;
    centres: Partial<CentreProps>[];
};
