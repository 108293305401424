import { ResourceConfig, Attribute, ResourceProps, Resource } from 'angular-jsonapi';
import { ResourceTypeTokenCredential } from 'app/shared/resource/resource';

@ResourceConfig({
    type: ResourceTypeTokenCredential,
})
export class MessageAuthenticateWs extends Resource {
    @Attribute()
    accessToken: string;

    constructor(props: Partial<MessageAuthenticateWsProps>) {
        super(props);
        if (props) {
            this.accessToken = props.accessToken;
        }
    }
}

export type MessageAuthenticateWsProps = Partial<ResourceProps> & {
    accessToken: string;
};
