import { Injectable } from '@angular/core';
import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web';
import { environment } from 'environments/environment';

@Injectable()
export class LoggingService {
    appInsights: ApplicationInsights;
    constructor() {
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: environment.appInsightsKey,
                enableAutoRouteTracking: true,
                distributedTracingMode: DistributedTracingModes.W3C,
                enableCorsCorrelation: true,
            },
        });
        this.appInsights.loadAppInsights();
    }

    logPageView(name?: string, url?: string): void {
        // option to call manually
        this.appInsights.trackPageView({
            name: name,
            uri: url,
        });
    }

    logException(exception: Error, severityLevel?: number): void {
        this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
    }
}
