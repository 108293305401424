import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

export const jsonapiInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    let headers = req.headers;
    if (!req.headers.has('Accept')) {
        headers = req.headers.set('Accept', 'application/vnd.api+json');
    }

    if (req.method !== 'GET' && !req.headers.has('Accept') && !(req.body instanceof FormData)) {
        headers = headers.set('Content-Type', 'application/vnd.api+json');
    }

    return next(req.clone({ headers }));
};
