import { Injectable, OnDestroy } from '@angular/core';
import { AccountService } from 'app/core/service/account.service';
import { OrganisationService } from 'app/modules/organisation/organisation.service';
import { Observable, filter, of, map, Subject, takeUntil, BehaviorSubject, retry } from 'rxjs';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { protectedResources } from './auth-config';
import { Role } from 'app/shared/types/account.types';

@Injectable({
    providedIn: 'root',
})
export class AuthService implements OnDestroy {
    public authenticated$: Observable<boolean>;
    public tokenRefresh$: Subject<void> = new Subject();

    private accessToken: string;
    private idToken: string;
    isIframe: boolean;
    private _authenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _organisationService: OrganisationService,
        private _accountService: AccountService,
        private authService: MsalService,
        private broadcastService: MsalBroadcastService
    ) {
        this.authenticated$ = this._authenticated.asObservable();

        this.broadcastService.msalSubject$
            .pipe(
                filter(
                    (msg: EventMessage) =>
                        msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS || msg.eventType === EventType.SSO_SILENT_SUCCESS
                ),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe((result: EventMessage) => {
                const payload = result.payload as AuthenticationResult;
                this.authService.instance.setActiveAccount(payload.account);
                if (result.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
                    if (this.accessToken !== payload.accessToken) {
                        this.accessToken = payload.accessToken;
                        this.idToken = payload.idToken;
                    }
                }
                this.signIn();
            });

        this.broadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe((result: EventMessage) => {
                if (result.error && (result.error.message.includes('AADB2C90091') || result.error.message.includes('AADB2C90077'))) {
                    this.isIframe ? this.authService.loginPopup() : this.authService.loginRedirect();
                }
            });

        this.broadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.LOGOUT_SUCCESS),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this._organisationService.removeActiveOrganisation();
                this._accountService.removeAgentId();

                // Set the authenticated flag to false
                this._authenticated.next(false);
            });
    }

    renewToken(): Observable<boolean> {
        return this.authService.acquireTokenSilent({ scopes: protectedResources.mmcApi.scopes }).pipe(
            map((result: AuthenticationResult) => {
                this.idToken = result.idToken;
                if (this.accessToken !== result.accessToken) {
                    return true;
                }
                this.tokenRefresh$.next();
                return false;
            }),
            retry(5)
        );
    }

    getAccessToken(): string {
        return this.accessToken;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Sign out
     */
    signOut(): void {
        this.isIframe
            ? this.authService.logoutPopup({
                  idTokenHint: this.idToken,
                  mainWindowRedirectUri: '/',
              })
            : this.authService.logoutRedirect({
                  idTokenHint: this.idToken,
              });
    }

    /**
     * Sign in
     */
    signIn(): Observable<boolean> {
        const currentAccount = this.authService.instance.getActiveAccount();
        // Assign values from token
        this._accountService.setAgentId(currentAccount?.idTokenClaims.sub);

        // Set agent name
        const name = currentAccount?.idTokenClaims.given_name as string;
        this._accountService.setAgentName(name);

        this._authenticated.next(true);
        return of(true);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        if (this.authService.instance.getAllAccounts().length > 0) {
            return of(true);
        }

        return of(false);
    }

    hasRole(): Observable<boolean> {
        return this._accountService.getAccount().pipe(map(account => !!account.roles.length));
    }

    hasPermissions(roles: Role[]): boolean {
        const currentRole = this._accountService.getRole();

        if (roles.includes(currentRole)) {
            switch (currentRole) {
                case Role.SUPERADMIN:
                    return true;
                case Role.ORG_SUPERADMIN:
                case Role.ORG_ADMIN: {
                    const activeOrgId = this._organisationService.getActiveOrganisation()?.id;
                    const targets = this._accountService.getTargetsByRole(currentRole);
                    return targets.includes(activeOrgId);
                }
                default:
                    return false;
            }
        }
        return false;
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
