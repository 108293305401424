import { Relationship, Resource, ResourceConfig, ResourceProps } from 'angular-jsonapi';
import { ResourceTypeAccountPermission } from 'app/shared/resource/resource';

@ResourceConfig({
    type: ResourceTypeAccountPermission,
})
export class AccountPermission extends Resource {
    @Relationship({ type: Resource })
    private permission: Resource;

    @Relationship({ type: Resource })
    private targets: Resource[];

    constructor(props: Partial<AccountPermissionProps>) {
        super(props);
        if (props?.permission) {
            this.permission = props.permission;
        }
        if (props?.targets) {
            this.targets = props.targets;
        }
    }

    Permission(): Resource {
        return this.permission;
    }

    Targets(): Resource[] {
        return this.targets;
    }
}

export type AccountPermissionProps = Partial<ResourceProps> & {
    permission: Resource;
    targets: Resource[];
};
