import { CommonModule } from '@angular/common';
import { withInterceptors, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthModule } from 'app/core/auth/auth.module';
import { WINDOW } from 'app/core/service/window.service';
import { MarkdownModule } from 'ngx-markdown';
import { ToastrModule } from 'ngx-toastr';
import { IconsModule } from './icons/icons.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { jsonapiInterceptor } from './interceptor/jsonapi.interceptor';

const CORE_PROVIDERS = [
    {
        provide: WINDOW,
        useFactory: (): Window => window,
    },
    provideHttpClient(withInterceptors([jsonapiInterceptor])),
];

@NgModule({
    imports: [CommonModule, AuthModule, IconsModule, MarkdownModule.forRoot(), ToastrModule.forRoot(), MatSnackBarModule],
    providers: [...CORE_PROVIDERS, provideHttpClient(withInterceptorsFromDi())],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
        if (parentModule) {
            throw new Error('CoreModule has already been loaded. Import this module in the Main.ts file only.');
        }
    }

    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [...CORE_PROVIDERS],
        };
    }
}
