import { inject, Injectable, Injector } from '@angular/core';
import { OrganisationApiService } from 'app/modules/organisation/organisation-api.service';
import { Role } from 'app/shared/types/account.types';
import { InfoList } from 'app/shared/types/list.types';
import { ActiveOrganisation } from 'app/shared/types/organisation.types';
import { BehaviorSubject, Observable, of, switchMap, tap } from 'rxjs';
import { AccountService } from '../../core/service/account.service';
import { ListResponse } from 'angular-jsonapi';
import { Organisation } from 'app/models/organisation.model';

const ORGANISATION_ID = 'organisationId';
const ORGANISATION_NAME = 'organisation_name';
const LIMIT = 15;

@Injectable({
    providedIn: 'root',
})
export class OrganisationService {
    organisationApiService: OrganisationApiService;
    private injector = inject(Injector);

    public activeOrganisation$: Observable<ActiveOrganisation>;
    public infoListOrganisations$: Observable<InfoList>;

    private _activeOrganisation = new BehaviorSubject<ActiveOrganisation>(null);
    private _infoListOrganisations = new BehaviorSubject<InfoList>({ totalCount: undefined, filter: '' });

    constructor(private accountService: AccountService) {
        const injector = Injector.create({ providers: [OrganisationApiService], parent: this.injector });
        this.organisationApiService = injector.get(OrganisationApiService);

        this.activeOrganisation$ = this._activeOrganisation.asObservable();
        this.infoListOrganisations$ = this._infoListOrganisations.asObservable();
    }

    getOrganisations(page: number, limit = LIMIT): Observable<ListResponse<Organisation>> {
        const filterOrgsIds = this.getOrganisationTargets();
        return this.organisationApiService.getOrganisationsApi(limit, page, filterOrgsIds).pipe(
            tap((res: ListResponse<Organisation>) => {
                if (res) {
                    this.setInfoListOrganisation(res?.meta().get('pagination')?.totalCount);
                }
            })
        );
    }

    getOrganisationId(): string {
        return localStorage.getItem(ORGANISATION_ID);
    }

    setActiveOrganisation(org: Organisation | ActiveOrganisation): void {
        this._activeOrganisation.next({ id: org.id, tradeName: org.tradeName });
        this.accountService.setRoleByOrg(org.id);
        localStorage.setItem(ORGANISATION_ID, org.id);
        localStorage.setItem(ORGANISATION_NAME, org.tradeName);
    }

    getActiveOrganisation(): ActiveOrganisation {
        return this._activeOrganisation.value;
    }

    loadActiveOrganisation(): ActiveOrganisation {
        if (!this._activeOrganisation.value) {
            if (this.getOrganisationId() && this.getOrganisationName()) {
                this.setActiveOrganisation({ id: this.getOrganisationId(), tradeName: this.getOrganisationName() } as ActiveOrganisation);
            }
        }
        return this._activeOrganisation.value;
    }

    checkActiveOrganisation(orgID?: string): Observable<boolean> {
        if (this.getInfoListOrganisation().totalCount === undefined) {
            return this.getOrganisations(0, 1000).pipe(
                switchMap((res: ListResponse<Organisation>, _index: number) => {
                    if (res?.meta().get('pagination')?.totalCount === 1) {
                        this.setActiveOrganisation(res.result()[0]);
                        return of(true);
                    }
                    if (orgID) {
                        const org = res.result().find(o => o.id === orgID);
                        if (org) {
                            this.setActiveOrganisation(org);
                            return of(true);
                        }
                    }
                    if (this.loadActiveOrganisation()) {
                        return of(true);
                    }
                    return of(false);
                })
            );
        }
        return of(true);
    }

    getOrganisationName(): string {
        return localStorage.getItem(ORGANISATION_NAME);
    }

    getInfoListOrganisation(): InfoList {
        return this._infoListOrganisations.value;
    }

    setInfoListOrganisation(totalCount: number = 0, filter: string = ''): void {
        return this._infoListOrganisations.next({ totalCount, filter });
    }

    removeActiveOrganisation(): void {
        localStorage.removeItem(ORGANISATION_ID);
        localStorage.removeItem(ORGANISATION_NAME);
        this._activeOrganisation.next(null);
    }

    private getOrganisationTargets(): string[] {
        const currentRole = this.accountService.getRole();
        if (currentRole !== Role.SUPERADMIN) {
            return this.accountService.getAllTargets();
        }
        return undefined;
    }
}
