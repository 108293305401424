import { ResourceConfig, Attribute, ResourceProps, Resource } from 'angular-jsonapi';
import { ResourceTypeCustomer } from 'app/shared/resource/resource';

@ResourceConfig({
    type: ResourceTypeCustomer,
})
export class Customer extends Resource {
    @Attribute()
    alternateName: string;

    @Attribute()
    firstName: string;

    @Attribute()
    lastName: string;

    @Attribute()
    phones: string[];

    @Attribute()
    onboardingStatuses: Map<string, OnboardingStatus>;

    constructor(props: Partial<CustomerProps>) {
        super(props);
        if (props) {
            this.alternateName = props.alternateName;
            this.firstName = props.firstName;
            this.lastName = props.lastName;
            this.phones = props.phones;
            this.onboardingStatuses = props.onboardingStatuses;
        }
    }

    isInOnboarding(channelGroupId: string): boolean {
        return this.onboardingStatuses && this.onboardingStatuses.has(channelGroupId) && this.onboardingStatuses.get(channelGroupId) !== OnboardingStatus.ONBOARDED;
    }
}

export type CustomerProps = Partial<ResourceProps> & {
    alternateName: string;
    firstName: string;
    lastName: string;
    phones: string[];
    onboardingStatuses: Map<string, OnboardingStatus>;
};

export enum OnboardingStatus {
    PENDING_ONBOARDING = 'PENDING_ONBOARDING',
    ONBOARDING_IN_PROGRESS = 'ONBOARDING_IN_PROGRESS',
    ONBOARDED = 'ONBOARDED',
}
