import { Inject, Injectable, InjectionToken, OnDestroy } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { environment } from 'environments/environment';
import { Observable, map, filter, switchMap, Subscription, take, of, distinctUntilChanged, BehaviorSubject, timer } from 'rxjs';
import { ActionWs, DialogueAgentsObservable, EventWs, InternalDialogueAgent, MessageInWs } from './chat.types';
import { MatSnackBar } from '@angular/material/snack-bar';
import { webSocket as rxjsWebsocket, WebSocketSubject } from 'rxjs/webSocket';
import { ResourceTypeChannelGroup, ResourceTypeChat, ResourceTypeMessage, ResourceTypeOrganisation, ResourceTypeWsSubscription } from 'app/shared/resource/resource';
import { isEqual } from 'lodash';
import { isDialogueAgentByName } from './chat.constants';
import { OrganisationService } from 'app/modules/organisation/organisation.service';
import { ChannelGroup } from 'app/models/channel-group.model';
import { DialogueAgent } from 'app/models/dialogue-agent.model';
import { Message } from 'app/models/message.model';
import { Chat } from 'app/models/chat.model';
import { MessageAuthenticateWs } from 'app/models/message-authenticate-ws.model';
import { Encoder, HttpMethod, IDocument } from 'angular-jsonapi';
import { MetaWs } from 'app/models/meta-ws.model';
import { DataWs, DataWsProps } from 'app/models/data-ws.model';
import { MessageSubscriptionWs } from 'app/models/message-subscription-ws.model';
import { StatusWs, StatusWsProps } from 'app/models/status-ws.model';

export const WEBSOCKET_CTOR = new InjectionToken<typeof rxjsWebsocket>('rxjs/webSocket.webSocket', {
    providedIn: 'root',
    factory: (): any => rxjsWebsocket,
});

@Injectable({ providedIn: 'root' })
export class ChatWebsocketService implements OnDestroy {
    public connection$: WebSocketSubject<any>;
    public totalChats$: DialogueAgentsObservable<number> = {};

    private connectionSubscription: Subscription = new Subscription();
    private retries: number = 0;
    private subscriptionIds: any = {};
    private sessionID: BehaviorSubject<string> = new BehaviorSubject(undefined);

    constructor(
        @Inject(WEBSOCKET_CTOR) public webSocket,
        private authService: AuthService,
        private organisationService: OrganisationService,
        private snackBar: MatSnackBar
    ) {
        this.manageConnection();
    }

    manageConnection(): void {
        this.authService.authenticated$
            .pipe(
                distinctUntilChanged(),
                filter(isAuth => isAuth)
            )
            .subscribe(() => this.authService.tokenRefresh$.next());
        this.authService.tokenRefresh$.subscribe(() => {
            if (!this.connection$) {
                this.connect();
            } else {
                this.authenticate();
            }
        });
    }

    connect(): void {
        const URL = `${environment.ws}`;

        if ((!this.connection$ || this.connection$.closed) && this.retries < 5) {
            this.connection$ = this.webSocket({
                url: URL,
                closeObserver: {
                    next: _err => {
                        this.resetConnection();
                    },
                },
            });

            if (this.connectionSubscription && !this.connectionSubscription.closed) {
                this.connectionSubscription.unsubscribe();
            }
            const sub = this.connection$
                .pipe(filter((message: StatusWsProps) => message.meta?.dataType === EventWs.STATUS && message.meta?.action === ActionWs.AUTHENTICATE && !message.errors))
                .subscribe(message => {
                    this.retries = 0;
                    this.sessionID.next(message.data.id);
                });

            this.connectionSubscription = this.connection$
                .pipe(
                    filter(
                        (message: StatusWsProps) =>
                            message.meta?.dataType === EventWs.STATUS &&
                            message.meta?.action === ActionWs.AUTHENTICATE &&
                            message.errors &&
                            message.errors.some(error => error.status === '401')
                    ),
                    switchMap(() => {
                        return this.authService.renewToken();
                    })
                )
                .subscribe();
            this.connectionSubscription.add(sub);
        } else {
            const snackbarRef = this.snackBar.open('It was not possible to connect to the server chat', 'Retry', {
                horizontalPosition: 'right',
                verticalPosition: 'bottom',
            });
            snackbarRef.onAction().subscribe(() => {
                this.retries = 0;
                this.connect();
            });
        }
    }

    authenticate(): void {
        const accessToken = this.authService.getAccessToken();
        const authReq: MessageAuthenticateWs = new MessageAuthenticateWs({
            accessToken,
        });
        const meta = new MetaWs({
            action: ActionWs.AUTHENTICATE,
        });
        const msg = new Encoder().Encode(authReq, Encoder.encodeWithRootMeta(meta));
        this.connection$?.next(msg);
    }

    resetConnection(): void {
        this.retries++;
        this.sessionID.next(undefined);
        this.connection$ = undefined;
        timer(Math.random() * 1000 + 2000)
            .pipe(switchMap(() => this.authService.renewToken().pipe(take(1))))
            .subscribe();
    }

    connectConversation(chatId: string): Observable<DataWs<Message>> {
        if (!Object.hasOwn(this.subscriptionIds, ResourceTypeChat)) {
            this.subscriptionIds[ResourceTypeChat] = {};
        }
        const search = { type: ResourceTypeChat, id: chatId };
        return this.createMultiplex(search, ResourceTypeChat, ResourceTypeMessage).pipe(
            map((message: DataWsProps) => {
                return new DataWs(Message, message);
            })
        );
    }

    connectChats(channelGroupId: string): Observable<DataWs<Chat>> {
        if (!Object.hasOwn(this.subscriptionIds, ResourceTypeChannelGroup)) {
            this.subscriptionIds[ResourceTypeChannelGroup] = {};
        }
        const search = { type: ResourceTypeChannelGroup, id: channelGroupId };
        return this.createMultiplex(search, ResourceTypeChannelGroup, ResourceTypeChat).pipe(
            map((message: DataWsProps) => {
                const msg = new DataWs(Chat, message);
                Object.keys(msg.meta.totalCountByAgents).forEach(key => (this.totalChats$[key] = of(msg.meta.totalCountByAgents[key])));
                return msg;
            })
        );
    }

    connectChannelGroup(channelGroupId: string, resourceType: ResourceType, agent?: DialogueAgent): Observable<DataWs<DialogueAgent>> {
        if (!Object.hasOwn(this.subscriptionIds, ResourceTypeChannelGroup)) {
            this.subscriptionIds[ResourceTypeChannelGroup] = {};
        }
        const search = { type: ResourceTypeChannelGroup, id: channelGroupId };

        return this.createMultiplex(search, ResourceTypeChannelGroup, resourceType).pipe(
            map((message: DataWsProps) => {
                const msg = new DataWs<DialogueAgent>(DialogueAgent, message);
                let humanAgent = msg.data;
                if (!isDialogueAgentByName(msg.data, InternalDialogueAgent.AGENT)) {
                    humanAgent = agent;
                }
                msg.data.getPermissions(humanAgent);
                return msg;
            })
        );
    }

    connectOrganisation(resourceType: ResourceType): Observable<DataWs<ChannelGroup>> {
        if (!Object.hasOwn(this.subscriptionIds, ResourceTypeOrganisation)) {
            this.subscriptionIds[ResourceTypeOrganisation] = {};
        }
        const organisationId = this.organisationService.getActiveOrganisation().id;
        const search = { type: ResourceTypeOrganisation, id: organisationId };

        return this.createMultiplex(search, ResourceTypeOrganisation, resourceType).pipe(
            map((message: DataWsProps) => {
                const msg = new DataWs<ChannelGroup>(ChannelGroup, message);
                return msg;
            })
        );
    }

    ngOnDestroy(): void {
        this.connection$?.complete();
        this.connection$?.unsubscribe();
    }

    private getOutMessage(subscriptionId: string, action: ActionWs, type: ResourceType, search: Resource): MessageSubscriptionWs {
        const msg: MessageSubscriptionWs = new MessageSubscriptionWs({
            type: ResourceTypeWsSubscription,
        });
        if (action === ActionWs.SUBSCRIBE) {
            msg.topic = type;
            msg.withTargets([
                {
                    type: search.type,
                    id: search.id,
                },
            ]);
        } else if (action === ActionWs.UNSUBSCRIBE) {
            msg.id = subscriptionId;
        }

        return msg;
    }

    private getOutMessageEncoded(subscriptionId: string, action: ActionWs, type: ResourceType, search: Resource): IDocument<MessageSubscriptionWs> {
        const meta = new MetaWs({ action });
        const msg = this.getOutMessage(subscriptionId, action, type, search);
        const encoders = [Encoder.encodeWithRootMeta(meta)];
        if (action === ActionWs.UNSUBSCRIBE) {
            encoders.push(Encoder.encodeAsClient(HttpMethod.Patch));
        }
        const message = new Encoder<MessageSubscriptionWs>().Encode(msg, ...encoders);

        return message;
    }

    private filterMessages(message: MessageInWs, subscriptionType: ResourceType, resourceType: ResourceType, search: Resource): boolean {
        const subsMsg = this.getOutMessage(this.subscriptionIds[subscriptionType][resourceType], ActionWs.SUBSCRIBE, resourceType, search);
        if (message.meta.dataType === EventWs.STATUS) {
            const msg = new StatusWs<MessageSubscriptionWs>(MessageSubscriptionWs, message);
            if (msg.meta?.action === ActionWs.SUBSCRIBE && msg.data.topic === subsMsg.topic && isEqual(msg.data?.Targets(), subsMsg.targets)) {
                this.subscriptionIds[subscriptionType][resourceType] = msg.data?.id;
            }
            return false;
        }
        return message.meta?.dataType === EventWs.DATA && message.meta?.subscriptionID === this.subscriptionIds[subscriptionType][resourceType];
    }

    private createMultiplex(search: Resource, subscriptionType: ResourceType, resourceType: ResourceType): Observable<any> {
        return this.sessionID.pipe(
            distinctUntilChanged(),
            filter(sessionId => !!sessionId),
            switchMap(() => {
                return this.connection$.multiplex(
                    () => this.getOutMessageEncoded(this.subscriptionIds[subscriptionType][resourceType], ActionWs.SUBSCRIBE, resourceType, search),
                    () => this.getOutMessageEncoded(this.subscriptionIds[subscriptionType][resourceType], ActionWs.UNSUBSCRIBE, resourceType, search),
                    (message: MessageInWs) => this.filterMessages(message, subscriptionType, resourceType, search)
                );
            })
        );
    }
}
