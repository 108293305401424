import { Relationship, Resource, ResourceProps } from 'angular-jsonapi';
import { Organisation, OrganisationProps } from './organisation.model';

export class ResourceWithOrganisation extends Resource {
    @Relationship({ type: Organisation })
    private organisation: Organisation;

    constructor(props?: Partial<ResourceWithOrganisationProps>) {
        super(props);
        if (props?.organisation) {
            this.organisation = new Organisation(props.organisation);
        }
    }

    Organisation(): Organisation {
        return this.organisation;
    }
    withOrganisation(org: Organisation): void {
        this.organisation = org;
    }
}

export interface ResourceWithOrganisationProps extends ResourceProps {
    organisation: Partial<OrganisationProps>;
}
