export enum DialogueAgentStatus {
    ENABLED = 'ENABLED',
    DISABLED = 'DISABLED',
}

export enum DialogueAgentVisibility {
    HIDDEN = 'HIDDEN',
    VISIBLE = 'VISIBLE',
}

export enum DialogueAgentOwner {
    EXTERNAL = 'EXTERNAL',
    INTERNAL = 'INTERNAL',
}

export enum DialoguePermision {
    CREATE = 'dialogues.create',
    CLOSE = 'dialogues.update',
}

export enum DialogueAgentEvents {
    NEW_INCOMING_MESSAGE = 'Inbound Messages',
    MESSAGE_STATUS_UPDATE = 'Delivery Status',
    NEW_DIALOGUE = 'Dialogue Assignment',
    DIALOGUE_STATUS_UPDATE = 'Dialogue Closure',
}
