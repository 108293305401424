export type ResourceType = string;

export const ResourceTypeCustomer: ResourceType = 'customers';
export const ResourceTypeChat: ResourceType = 'chats';
export const ResourceTypeMessage: ResourceType = 'messages';
export const ResourceTypeDialogue: ResourceType = 'dialogues';
export const ResourceTypeDialogueAgent: ResourceType = 'dialogue-agents';
export const ResourceTypeWsSubscription: ResourceType = 'ws-subscriptions';
export const ResourceTypeChannelGroup: ResourceType = 'channel-groups';
export const ResourceTypeChannel: ResourceType = 'messaging-channels';
export const ResourceTypeOrganisation: ResourceType = 'organisations';
export const ResourceTypeTokenCredential: ResourceType = 'token-credentials';
export const ResourceTypeFirefishIntegration: ResourceType = 'firefish-integrations';
export const ResourceTypeUIBCredential: ResourceType = 'uib-credentials';
export const ResourceTypeIntegration: ResourceType = 'integrations';
export const ResourceTypeCentre: ResourceType = 'centres';
export const ResourceTypeAccountPermission: ResourceType = 'account-permissions';
export const ResouceTypeMediaFile: ResourceType = 'media-files';
export const ResourceTypeTemplate: ResourceType = 'templates';
export const ResourceTypeAddress: ResourceType = 'addresses';
export const ResourceTypeWeeklyOpeningHours: ResourceType = 'weekly-opening-hours';
export const ResourceTypeCustomOpeningHours: ResourceType = 'custom-opening-hours';
export const ResourceTypeRegionalHolidays: ResourceType = 'regional-holidays';
export const ResourceTypeOnboardingConfig: ResourceType = 'customer-onboarding-configs';
export const ResourceTypeNotification: ResourceType = 'notification-messages';
export const ResourceTypeIntegrationCredentials: ResourceType = 'service-account-credentials';
