import { NgModule, inject } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors } from '@angular/common/http';
import { AuthService } from 'app/core/auth/auth.service';
import { mockRequestInterceptor } from 'app/mock-files/mock-request.interceptor';
import { authInterceptor } from './auth.interceptor';
import { IPublicClientApplication, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { loginRequest, msalConfig, protectedResources } from './auth-config';
import {
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG,
    MsalGuard,
    MsalGuardConfiguration,
    MsalInterceptor,
    MsalInterceptorConfiguration,
    MsalModule,
    MsalService,
    ProtectedResourceScopes,
} from '@azure/msal-angular';

export const msalInstanceFactory = (): IPublicClientApplication => {
    return new PublicClientApplication(msalConfig);
};

export const msalInterceptorConfigFactory = (): MsalInterceptorConfiguration => {
    const authService = inject(AuthService);
    const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();

    protectedResourceMap.set(protectedResources.mmcApi.endpoint, [...protectedResources.mmcApi.scopes]);

    return {
        interactionType: authService.isIframe ? InteractionType.Popup : InteractionType.Redirect,
        protectedResourceMap,
    };
};

export const msalGuardConfigFactory = (): MsalGuardConfiguration => {
    const authService = inject(AuthService);
    return {
        interactionType: authService.isIframe ? InteractionType.Popup : InteractionType.Redirect,
        authRequest: loginRequest,
    };
};

@NgModule({
    imports: [MsalModule],
    providers: [
        AuthService,
        MsalGuard,
        MsalService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: msalInstanceFactory,
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: msalGuardConfigFactory,
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: msalInterceptorConfigFactory,
        },
        provideHttpClient(withInterceptors([mockRequestInterceptor, authInterceptor])),
    ],
})
export class AuthModule {}
