import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import { environment } from 'environments/environment';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export const msalConfig: Configuration = {
    auth: {
        clientId: environment.b2cPolicy.clientId,
        authority: environment.b2cPolicy.authority,
        knownAuthorities: [environment.b2cPolicy.authorityDomain],
        redirectUri: '/chats',
        postLogoutRedirectUri: '/',
        authorityMetadata:
            // eslint-disable-next-line max-len
            `{"issuer":"${environment.b2cPolicy.authorityDomain}/${environment.b2cPolicy.tenantId}/v2.0/","authorization_endpoint":"${environment.b2cPolicy.authority}/oauth2/v2.0/authorize","token_endpoint":"${environment.b2cPolicy.authority}/oauth2/v2.0/token","end_session_endpoint":"${environment.b2cPolicy.authority}/oauth2/v2.0/logout","jwks_uri":"${environment.b2cPolicy.authority}/discovery/v2.0/keys","response_modes_supported":["query","fragment","form_post"],"response_types_supported":["code","codeid_token","codetoken","codeid_tokentoken","id_token","id_tokentoken","token","tokenid_token"],"scopes_supported":["openid"],"subject_types_supported":["pairwise"],"id_token_signing_alg_values_supported":["RS256"],"token_endpoint_auth_methods_supported":["client_secret_post","client_secret_basic"],"claims_supported":["given_name","idp","emails","family_name","oid","sub","idp_access_token","name","tfp","isForgotPassword","iss","iat","exp","aud","acr","nonce","auth_time"]}`,
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: isIE,
    },
    system: {
        allowNativeBroker: false, // Disables native brokering support
        loggerOptions: {
            loggerCallback: (logLevel: LogLevel, message: string) => {
                console.log('AuthLogger', message);
            },
            logLevel: LogLevel.Warning,
            piiLoggingEnabled: false,
        },
    },
};

export const protectedResources = {
    mmcApi: {
        endpoint: `${environment.url}/**`,
        scopes: [
            `${environment.b2cPolicy.authorityDomain}/auth/messaging.channels`,
            `${environment.b2cPolicy.authorityDomain}/auth/messaging.publisher`,
            `${environment.b2cPolicy.authorityDomain}/auth/messaging.chats`,
            `${environment.b2cPolicy.authorityDomain}/auth/admin.directory.customer`,
            `${environment.b2cPolicy.authorityDomain}/auth/admin.directory.centre`,
            `${environment.b2cPolicy.authorityDomain}/auth/admin.directory.user`,
            `${environment.b2cPolicy.authorityDomain}/auth/admin.directory.user.security`,
            `${environment.b2cPolicy.authorityDomain}/auth/admin.directory.orgunit`,
        ],
    },
};

export const loginRequest = {
    scopes: [],
};
