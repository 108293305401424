/* eslint-disable max-len */

const domain = 'dev-api.messagemycustomer.com';

export const environment = {
    production: false,
    ws: `wss://${domain}/ws`,
    url: `https://${domain}`,
    appInsightsKey: 'd8a55557-358b-47e7-85fc-92c01255996e',
    b2cPolicy: {
        name: 'b2c_1_signin1',
        authority: 'https://dev-login.payemoji.com/mmcdevadb2c.onmicrosoft.com/b2c_1_signin1',
        authorityDomain: 'https://dev-login.payemoji.com',
        clientId: '928277b0-345a-4473-9e0f-a40b7f2685c4',
        tenantId: '9c4320ce-6f95-4e72-b851-9291c3de4a3b',
    },
};
