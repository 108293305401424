import { ResourceConfig, Attribute, ResourceProps, Resource, Relationship } from 'angular-jsonapi';
import { ResourceTypeWsSubscription } from 'app/shared/resource/resource';

@ResourceConfig({
    type: ResourceTypeWsSubscription,
})
export class MessageSubscriptionWs extends Resource {
    @Attribute()
    topic: ResourceType;

    @Relationship({ type: Resource })
    targets: Resource[];

    constructor(props: Partial<MessageSubscriptionWsProps>) {
        super(props);
        if (props) {
            this.topic = props.topic;

            if (props.targets) {
                this.targets = [];
                props.targets.forEach(target => {
                    this.targets = [...this.targets, new Resource(target)];
                });
            }
        }
    }

    Targets(): Resource[] {
        return this.targets;
    }

    withTargets(targets: Partial<ResourceProps>[]): void {
        this.targets = [];
        targets.forEach(target => {
            this.targets = [...this.targets, new Resource(target)];
        });
    }
}

export type MessageSubscriptionWsProps = Partial<ResourceProps> & {
    topic: ResourceType;
    targets: Partial<ResourceProps>[];
};
