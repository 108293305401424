import { ResourceConfig, Resource, ResourceProps, Attribute } from 'angular-jsonapi';
import { HolidaysHours } from 'app/modules/centre/centre.types';
import { ResourceTypeCentre } from 'app/shared/resource/resource';
import { Organisation, OrganisationProps } from './organisation.model';
import { Address, AddressProps } from './address.model';

@ResourceConfig({
    type: ResourceTypeCentre,
})
export class Centre extends Resource {
    @Attribute()
    description: string;

    @Attribute()
    gMapsURL: string;

    @Attribute()
    name: string;

    @Attribute()
    notes: string[];

    @Attribute()
    timezone: string;

    @Attribute()
    weeklyScheduleOnHolidaysConstraint: HolidaysHours;

    @Attribute({
        transformer: {
            serialize: value => (value ? { id: value.id } : undefined),
            deserialize: value => value,
        },
    })
    address: Partial<Address>;

    @Attribute({
        transformer: {
            serialize: () => undefined,
            deserialize: value => value,
        },
    })
    organisation: Partial<Organisation>;

    constructor(data: Partial<CentreProps>) {
        super(data);
        if (data) {
            this.description = data.description;
            this.gMapsURL = data.gMapsURL;
            this.name = data.name;
            this.notes = data.notes;
            this.timezone = data.timezone;
            this.weeklyScheduleOnHolidaysConstraint = data.weeklyScheduleOnHolidaysConstraint;

            if (data.address) {
                this.address = new Address(data.address);
            }

            if (data.organisation) {
                this.organisation = new Organisation(data.organisation);
            }
        }
    }

    toProps(): CentreProps {
        const centreProps: CentreProps = {
            description: this.description,
            gMapsURL: this.gMapsURL,
            name: this.name,
            notes: this.notes,
            timezone: this.timezone,
            weeklyScheduleOnHolidaysConstraint: this.weeklyScheduleOnHolidaysConstraint,
            address: { id: this.address?.id },
            organisation: { id: this.organisation?.id },
        };
        return centreProps;
    }
}

export type CentreProps = Partial<ResourceProps> & {
    description: string;
    gMapsURL: string;
    name: string;
    notes: string[];
    timezone: string;
    weeklyScheduleOnHolidaysConstraint: HolidaysHours;
    address: Partial<AddressProps>;
    organisation: Partial<OrganisationProps>;
};
