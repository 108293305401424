import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { LoggingService } from './logging.service';

@Injectable()
export class ExceptionService extends ErrorHandler {
    constructor(private loggingService: LoggingService) {
        super();
    }

    handleError(error: Error): void {
        super.handleError(error);
        if (
            (error instanceof HttpErrorResponse && error.status === 401) ||
            error instanceof CloseEvent ||
            (error instanceof Event && (error as Event).target instanceof WebSocket)
        ) {
            return;
        }
        this.loggingService.logException(error);
    }
}
