import { Component, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Subject, filter, takeUntil } from 'rxjs';
import { AuthService } from './core/auth/auth.service';
import { RouterOutlet } from '@angular/router';
import { ChatWebsocketService } from './modules/chat/chat-websocket.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet],
})
export class AppComponent implements OnInit, OnDestroy {
    loginDisplay = false;
    private unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private broadcastService: MsalBroadcastService,
        private authMsalService: MsalService,
        private authService: AuthService,
        private chatWebSocketService: ChatWebsocketService
    ) {}

    ngOnInit(): void {
        this.authService.isIframe = window !== window.parent && !window.opener;
        this.setLoginDisplay();

        this.broadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this.unsubscribeAll)
            )
            .subscribe(() => {
                this.setLoginDisplay();
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.complete();
        this.unsubscribeAll = undefined;
    }

    setLoginDisplay(): void {
        this.loginDisplay = this.authMsalService.instance.getAllAccounts().length > 0;
    }
}
