import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SubmitButtonService {
    errorRequest$: Observable<boolean>;
    private errorRequestSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor() {
        this.errorRequest$ = this.errorRequestSubject.asObservable();
    }

    setErrorRequest(error: boolean): void {
        this.errorRequestSubject.next(error);
    }
}
