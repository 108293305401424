import { ResourceTypeChannelGroup } from 'app/shared/resource/resource';
import { ResourceConfig, Attribute, ResourceProps, Relationship } from 'angular-jsonapi';
import { DialogueAgent, DialogueAgentProps } from './dialogue-agent.model';
import { ResourceWithOrganisation } from './resource-with-organisation.model';

@ResourceConfig({
    type: ResourceTypeChannelGroup,
})
export class ChannelGroup extends ResourceWithOrganisation {
    @Attribute()
    name: string;

    @Attribute()
    description: string;

    @Relationship({ type: DialogueAgent })
    private defaultDialogueAgent: DialogueAgent;

    constructor(props: Partial<ChannelGroupProps>) {
        super(props);
        if (props) {
            this.name = props.name;
            this.description = props.description;

            if (props.defaultDialogueAgent) {
                this.defaultDialogueAgent = new DialogueAgent(props.defaultDialogueAgent);
            }
        }
    }

    DefaultDialogueAgent(): DialogueAgent {
        return this.defaultDialogueAgent;
    }

    withDefaultDialogueAgent(defaultDialogueAgent: DialogueAgent): void {
        this.defaultDialogueAgent = defaultDialogueAgent;
    }
}

export type ChannelGroupProps = Partial<ResourceProps> & {
    description: string;
    name: string;
    defaultDialogueAgent: Partial<DialogueAgentProps>;
};
