import { ResourceProps, Resource, Meta, Wrapped, WrappedResource, Decoder, ModelType } from 'angular-jsonapi';
import { MetaWs, MetaWsProps } from './meta-ws.model';

export interface Error {
    status: string;
    detail: string;
}

export class StatusWs<R extends Resource> extends WrappedResource {
    @Meta({ type: MetaWs })
    meta: MetaWs;

    @Wrapped()
    data: R;

    @Meta({ type: Error })
    errors: Error[];

    constructor(modelType: ModelType<R>, props: Partial<StatusWsProps>) {
        super(props);
        if (props) {
            const resource = new Decoder<R>(modelType).Decode(props);
            this.data = resource.Data();
            this.meta = new MetaWs(props.meta);
            this.errors = props.errors;
        }
    }
}

export type StatusWsProps = Partial<ResourceProps> & {
    meta: Partial<MetaWsProps>;
    data: Partial<ResourceProps>;
    errors: Error[];
};
