export const getApiResponse = (response: any, totalCount?: number, included?: any[]): JsonApiResponse<any> => {
    const res = { data: response };
    if (Array.isArray(response)) {
        res['meta'] = {
            pagination: {
                totalCount: totalCount ?? response.length,
            },
        };
    }
    if (included) {
        res['included'] = included;
    }
    return res;
};
