import { ResourceProps, WrappedResource, Wrapped } from 'angular-jsonapi';
import { ActionWs, EventTypeWs, EventWs } from 'app/modules/chat/chat.types';

export class MetaWs extends WrappedResource {
    @Wrapped()
    action: ActionWs;

    @Wrapped()
    dataType: EventWs;

    @Wrapped()
    eventType: EventTypeWs;

    @Wrapped()
    subscriptionID: string;

    @Wrapped()
    totalCountByAgents: { [key in string]: number };

    constructor(props: Partial<MetaWsProps>) {
        super(props);
        if (props) {
            this.action = props.action;
            this.dataType = props.dataType;
            this.eventType = props.eventType;
            this.subscriptionID = props.subscriptionID;
            this.totalCountByAgents = props.totalCountByAgents;
        }
    }
}

export type MetaWsProps = Partial<ResourceProps> & {
    action: ActionWs;
    dataType: EventWs;
    eventType: EventTypeWs;
    subscriptionID: string;
    totalCountByAgents: { [key in string]: number };
};
