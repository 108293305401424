import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class QueryParamsService {
    constructor(private router: Router) {}

    getQueryParams(path): Params {
        let queryParams = this.getUrlQueryParams();
        if (!queryParams) {
            queryParams = this.getSavedQueryParams(path);
        }
        return queryParams;
    }

    saveUrlQueryParams(path: string[]): void {
        const queryParams = this.getUrlQueryParams();
        if (queryParams) {
            const params = {};
            Object.keys(queryParams).forEach(param => {
                params[param] = queryParams[param];
            });
            sessionStorage.setItem('queryParams', JSON.stringify(params));
            sessionStorage.setItem('path', path.join('/'));
        }
    }

    deleteSavedQueryParams(): void {
        sessionStorage.removeItem('path');
        sessionStorage.removeItem('queryParams');
    }

    getSavedQueryParams(path: string[]): Params {
        const pathSaved = sessionStorage.getItem('path');
        if (pathSaved && pathSaved === path.join('/')) {
            const queryParamsSaved = sessionStorage.getItem('queryParams');
            if (queryParamsSaved) {
                return JSON.parse(queryParamsSaved);
            }
        }
        return null;
    }

    private getUrlQueryParams(): Params {
        const queryParams = this.router.getCurrentNavigation().initialUrl.queryParams;
        return Object.keys(queryParams).length ? queryParams : null;
    }
}
