import { faUserGroup } from '@fortawesome/pro-regular-svg-icons/faUserGroup';
import { faChartNetwork } from '@fortawesome/pro-regular-svg-icons/faChartNetwork';
import { faGear } from '@fortawesome/pro-regular-svg-icons/faGear';
import { faHexagonCheck } from '@fortawesome/pro-regular-svg-icons/faHexagonCheck';
import { faHouse } from '@fortawesome/pro-regular-svg-icons/faHouse';
import { faShop } from '@fortawesome/pro-regular-svg-icons/faShop';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { Role } from 'app/shared/types/account.types';

/* eslint-disable @typescript-eslint/naming-convention */
export enum Path {
    CHAT = 'chats',
    CENTRES = 'centres',
    CHANNELS = 'channels',
    ORGANISATIONS = 'organisations',
    LOGIN = 'login',
    FORBIDDEN = 'forbidden',
    INTEGRATIONS = 'integrations',
    DIALOGUE_AGENTS = 'dialogue-agents',
    THIRD_PARTY_INTEGRATIONS = 'third-party-integrations',
}

export const MenuRoute: Readonly<Record<string, string>> = {
    CHAT: `/${Path.CHAT}`,
    CENTRES: `/${Path.CENTRES}`,
    CHANNELS: `/${Path.CHANNELS}`,
    ORGANISATIONS: `/${Path.ORGANISATIONS}`,
    LOGIN: `/${Path.LOGIN}`,
    INTEGRATIONS: `/${Path.INTEGRATIONS}`,
    DIALOGUE_AGENTS: `/${Path.DIALOGUE_AGENTS}`,
    THIRD_PARTY_INTEGRATIONS: `/${Path.THIRD_PARTY_INTEGRATIONS}`,
};

/* eslint-enable @typescript-eslint/naming-convention */

export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'chats',
        title: 'Customer Chat',
        type: 'basic',
        icon: 'user-group',
        link: MenuRoute.CHAT,
        classes: {
            icon: 'fa-lg',
        },
        meta: {
            withoutOrg: false,
            roles: [Role.SUPERADMIN, Role.ORG_ADMIN, Role.ORG_SUPERADMIN],
        },
    },
    {
        id: 'centres',
        title: 'Centres',
        type: 'basic',
        icon: 'shop',
        link: MenuRoute.CENTRES,
        classes: {
            icon: 'fa-lg',
        },
        meta: {
            withoutOrg: false,
            roles: [Role.SUPERADMIN, Role.ORG_ADMIN, Role.ORG_SUPERADMIN],
        },
    },
    {
        id: 'channels',
        title: 'Channels Management',
        type: 'basic',
        icon: 'chart-network',
        link: MenuRoute.CHANNELS,
        classes: {
            icon: 'fa-lg',
        },
        meta: {
            withoutOrg: false,
            roles: [Role.SUPERADMIN, Role.ORG_ADMIN, Role.ORG_SUPERADMIN],
        },
    },
    /* Example menu item for superadmin */
    {
        id: 'settings',
        title: 'Actor Config',
        type: 'collapsable',
        icon: 'gear',
        classes: {
            icon: 'fa-lg',
        },
        meta: {
            withoutOrg: false,
            roles: [Role.SUPERADMIN, Role.ORG_SUPERADMIN],
        },
        children: [
            {
                id: 'integrations',
                title: 'Integration Credentials',
                type: 'basic',
                link: MenuRoute.INTEGRATIONS,
                classes: {
                    icon: 'fa-lg',
                },
                meta: {
                    withoutOrg: false,
                    roles: [Role.SUPERADMIN, Role.ORG_SUPERADMIN],
                },
            },
            {
                id: 'dialogue-agents',
                title: 'Dialogue Agents',
                type: 'basic',
                link: MenuRoute.DIALOGUE_AGENTS,
                classes: {
                    icon: 'fa-lg',
                },
                meta: {
                    withoutOrg: false,
                    roles: [Role.SUPERADMIN, Role.ORG_SUPERADMIN],
                },
            },
            {
                id: 'third-party-integrations',
                title: 'Third Party Integrations',
                type: 'basic',
                link: MenuRoute.THIRD_PARTY_INTEGRATIONS,
                classes: {
                    icon: 'fa-lg',
                },
                meta: {
                    withoutOrg: false,
                    roles: [Role.SUPERADMIN, Role.ORG_SUPERADMIN],
                },
            },
        ],
    },
    {
        id: 'management_portal',
        title: 'Management Portal',
        type: 'basic',
        icon: 'house',
        link: 'https://portal.payemoji.com/dashboard',
        externalLink: true,
        target: '_blank',
        classes: {
            icon: 'fa-lg',
        },
        meta: {
            withoutOrg: true,
        },
    },
];

export const footer: FuseNavigationItem[] = [
    {
        id: 'organisation',
        title: 'Org Selector',
        type: 'basic',
        icon: 'hexagon-check',
        link: MenuRoute.ORGANISATIONS,
        classes: {
            icon: 'fa-xl',
        },
    },
];

export const menuIcons = [faUserGroup, faHouse, faHexagonCheck, faShop, faChartNetwork, faGear];
