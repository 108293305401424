import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { FuseFullscreenComponent } from '@fuse/components/fullscreen';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import { FuseNavigationItem, FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { AuthService } from 'app/core/auth/auth.service';
import { footer, futuristicNavigation } from 'app/core/navigation/navigation-menu';
import { Navigation } from 'app/core/navigation/navigation.types';
import { OrganisationService } from 'app/modules/organisation/organisation.service';
import { UserComponent } from 'app/layout/common/user/user.component';
import { CustomerInfoComponent } from 'app/modules/chat/customer-info/customer-info.component';
import { ActiveOrganisation } from 'app/shared/types/organisation.types';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'dense-layout',
    templateUrl: './dense.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [FuseLoadingBarComponent, FuseVerticalNavigationComponent, MatButtonModule, MatIconModule, FuseFullscreenComponent, UserComponent, RouterOutlet],
})
export class DenseLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;
    isDesktopScreen: boolean;
    navigation: Navigation;
    footer: Navigation;
    navigationAppearance: 'default' | 'dense' = 'dense';
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _organisationService: OrganisationService,
        private _authService: AuthService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.navigation = { default: futuristicNavigation };
        this.footer = { default: footer };
        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$.pipe(takeUntil(this._unsubscribeAll)).subscribe(({ matchingAliases }) => {
            // Check if the screen is small
            this.isScreenSmall = !matchingAliases.includes('md');
            this.isDesktopScreen = matchingAliases.includes('lg');

            // Change the navigation appearance
            this.navigationAppearance = this.isScreenSmall ? 'default' : 'dense';
        });

        this._organisationService.activeOrganisation$.pipe(takeUntil(this._unsubscribeAll)).subscribe((org: ActiveOrganisation) => {
            const orgIndex = this.footer.default?.findIndex((item: FuseNavigationItem) => item.id === 'organisation');
            if (orgIndex !== -1 && this.footer.default[orgIndex]) {
                this.footer.default[orgIndex].subtitle = org?.tradeName ?? '';
            }
            this.navigation.default = this.hideNavigationItems(this.navigation.default, org);

            this.footer.default[orgIndex].hidden = (): boolean => this._organisationService.getInfoListOrganisation()?.totalCount <= 1;
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    /**
     * Toggle the navigation appearance
     */
    toggleNavigationAppearance(): void {
        const customerInfo = this._fuseNavigationService.getComponent<CustomerInfoComponent>('customerInfo');
        this.navigationAppearance = this.navigationAppearance === 'default' ? 'dense' : 'default';
        if (this.isDesktopScreen && customerInfo && this.navigationAppearance === 'default') {
            customerInfo.onClose();
        }
    }

    aparenceChanged(event): void {
        this.navigationAppearance = event;
    }

    private hideNavigationItems(navigationItems: FuseNavigationItem[], org: ActiveOrganisation): any {
        navigationItems = navigationItems.map(item => {
            if (item.children?.length > 0) {
                item.children = this.hideNavigationItems(item.children, org);
            }
            item.hidden = (): boolean => {
                if (!item.meta?.withoutOrg || item.meta.roles) {
                    if (!item.meta?.withoutOrg && !org) {
                        return true;
                    }
                    if (item.meta?.roles && item.meta?.roles.length) {
                        return !this._authService.hasPermissions(item.meta?.roles);
                    }
                    return false;
                }
            };
            return item;
        });
        return navigationItems;
    }
}
