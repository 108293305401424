import { ResourceConfig, Resource, ResourceProps, Attribute } from 'angular-jsonapi';
import { ResourceTypeAddress } from 'app/shared/resource/resource';

@ResourceConfig({
    type: ResourceTypeAddress,
})
export class Address extends Resource {
    @Attribute()
    lines: string[];

    @Attribute()
    postalCode: string;

    @Attribute({
        transformer: {
            serialize: value => ({
                alpha2Code: value,
            }),
            deserialize: value => value.alpha2Code,
        },
    })
    country: string;

    constructor(data: Partial<AddressProps>) {
        super(data);
        if (data) {
            this.lines = data.lines;
            this.postalCode = data.postalCode;
            this.country = data.country?.alpha2Code;
        }
    }

    toProps(): AddressProps {
        const articleProps: AddressProps = {
            lines: this.lines,
            postalCode: this.postalCode,
            country: {
                alpha2Code: this.country,
            },
        };
        return articleProps;
    }
}

export type AddressProps = Partial<ResourceProps> & {
    lines: string[];
    postalCode: string;
    country: {
        alpha2Code: string;
    };
};
