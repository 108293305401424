import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp';
import { faUserHeadset } from '@fortawesome/pro-duotone-svg-icons/faUserHeadset';
import { faMessageBot } from '@fortawesome/pro-duotone-svg-icons/faMessageBot';
import { faFloppyDiskPen } from '@fortawesome/pro-duotone-svg-icons/faFloppyDiskPen';
import { faArrowsMaximize } from '@fortawesome/pro-duotone-svg-icons/faArrowsMaximize';
import { faAnglesDown } from '@fortawesome/pro-regular-svg-icons/faAnglesDown';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons/faArrowLeft';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons/faEllipsisVertical';
import { faExternalLinkSquareAlt } from '@fortawesome/pro-regular-svg-icons/faExternalLinkSquareAlt';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons/faMagnifyingGlass';
import { faMessages } from '@fortawesome/pro-regular-svg-icons/faMessages';
import { faPaperPlaneTop } from '@fortawesome/pro-regular-svg-icons/faPaperPlaneTop';
import { faPenSlash } from '@fortawesome/pro-regular-svg-icons/faPenSlash';
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons/faPenToSquare';
import { faPhoneSquareAlt } from '@fortawesome/pro-regular-svg-icons/faPhoneSquareAlt';
import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark';
import { faFloppyDiskPen as faFloppyDiskP } from '@fortawesome/pro-regular-svg-icons/faFloppyDiskPen';
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons/faAngleDown';
import { faAngleUp } from '@fortawesome/pro-regular-svg-icons/faAngleUp';
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons/faTrashCan';
import { faHexagonExclamation } from '@fortawesome/pro-regular-svg-icons/faHexagonExclamation';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons/faTriangleExclamation';
import { faCopy } from '@fortawesome/pro-regular-svg-icons/faCopy';
import { faCopy as fasCopy } from '@fortawesome/pro-solid-svg-icons/faCopy';
import { faCircleDown } from '@fortawesome/pro-regular-svg-icons/faCircleDown';
import { faFilePdf } from '@fortawesome/pro-regular-svg-icons/faFilePdf';
import { faFileImage } from '@fortawesome/pro-regular-svg-icons/faFileImage';
import { faFileExcel } from '@fortawesome/pro-regular-svg-icons/faFileExcel';
import { faFilePowerpoint } from '@fortawesome/pro-regular-svg-icons/faFilePowerpoint';
import { faFile } from '@fortawesome/pro-regular-svg-icons/faFile';
import { faFileWord } from '@fortawesome/pro-regular-svg-icons/faFileWord';
import { faFileLines } from '@fortawesome/pro-regular-svg-icons/faFileLines';
import { faAnglesDown as fasAnglesDown } from '@fortawesome/pro-solid-svg-icons/faAnglesDown';
import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons/faCircleInfo';
import { faPlay } from '@fortawesome/pro-solid-svg-icons/faPlay';
import { faUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons/faUpRightFromSquare';
import { faCheckDouble } from '@fortawesome/pro-solid-svg-icons/faCheckDouble';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faClock } from '@fortawesome/pro-solid-svg-icons/faClock';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons/faCircleExclamation';
import { faDownToBracket } from '@fortawesome/pro-solid-svg-icons/faDownToBracket';
import { faPlus as faSolidPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { faCommentPlus } from '@fortawesome/pro-solid-svg-icons/faCommentPlus';
import { faLocationPlus } from '@fortawesome/pro-solid-svg-icons/faLocationPlus';
import { faPlugCirclePlus } from '@fortawesome/pro-solid-svg-icons/faPlugCirclePlus';
import { faLayerPlus } from '@fortawesome/pro-solid-svg-icons/faLayerPlus';
import { faMessagePlus } from '@fortawesome/pro-solid-svg-icons/faMessagePlus';
import { faEye } from '@fortawesome/pro-regular-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/pro-regular-svg-icons/faEyeSlash';
import { faCommentSms } from '@fortawesome/pro-regular-svg-icons/faCommentSms';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons/faArrowRight';
import { faCommentSms as fasCommentSms } from '@fortawesome/pro-solid-svg-icons/faCommentSms';
import { faFolderOpen } from '@fortawesome/free-regular-svg-icons/faFolderOpen';
import { faBrainCircuit } from '@fortawesome/pro-duotone-svg-icons/faBrainCircuit';
import { faInboxes } from '@fortawesome/pro-duotone-svg-icons/faInboxes';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons/faCircleXmark';
import { faCircleXmark as fasCircleXmark } from '@fortawesome/free-solid-svg-icons/faCircleXmark';
import { faMemoPad } from '@fortawesome/pro-regular-svg-icons/faMemoPad';
import { faCirclePlus } from '@fortawesome/pro-solid-svg-icons/faCirclePlus';
import { faBagShopping } from '@fortawesome/pro-regular-svg-icons/faBagShopping';
import { faCartShopping } from '@fortawesome/pro-regular-svg-icons/faCartShopping';
import { faBooks } from '@fortawesome/pro-regular-svg-icons/faBooks';
import { faBarsFilter } from '@fortawesome/pro-regular-svg-icons/faBarsFilter';
import { faRotateRight } from '@fortawesome/pro-regular-svg-icons/faRotateRight';
import { faFilterList } from '@fortawesome/pro-regular-svg-icons/faFilterList';
import { faXmarkLarge } from '@fortawesome/pro-solid-svg-icons/faXmarkLarge';

export const ICONS = [
    faMagnifyingGlass,
    faArrowLeft,
    faMessageBot,
    faUserHeadset,
    faPaperPlaneTop,
    faAnglesDown,
    fasAnglesDown,
    faPenToSquare,
    faPenSlash,
    faFloppyDiskPen,
    faFloppyDiskP,
    faExternalLinkSquareAlt,
    faPhoneSquareAlt,
    faArrowsMaximize,
    faMessages,
    faXmark,
    faEllipsisVertical,
    faPlay,
    faTrash,
    faCircleInfo,
    faAngleDown,
    faAngleUp,
    faLocationPlus,
    faCircle,
    faUpRightFromSquare,
    faPlus,
    faTrashCan,
    faWhatsapp,
    faCheckDouble,
    faCheck,
    faClock,
    faCircleExclamation,
    faTriangleExclamation,
    faHexagonExclamation,
    faDownToBracket,
    faSolidPlus,
    faPlugCirclePlus,
    faCommentPlus,
    faCopy,
    faCircleDown,
    faFilePdf,
    faFileImage,
    faFileExcel,
    faFilePowerpoint,
    faFile,
    faFileWord,
    faFileLines,
    faLayerPlus,
    faMessagePlus,
    faEye,
    faEyeSlash,
    fasCopy,
    faCommentSms,
    faArrowRight,
    fasCommentSms,
    faFolderOpen,
    faInboxes,
    faBrainCircuit,
    faCircleXmark,
    faMemoPad,
    faCirclePlus,
    faBagShopping,
    faCartShopping,
    faBooks,
    faBarsFilter,
    faRotateRight,
    fasCircleXmark,
    faFilterList,
    faXmarkLarge,
];
