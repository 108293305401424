/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IMeta, ListResponse } from 'angular-jsonapi';
import { Organisation } from 'app/models/organisation.model';
import { environment } from 'environments/environment';
import { Observable, map } from 'rxjs';

@Injectable()
export class OrganisationApiService {
    private url = environment.url;

    constructor(private _httpClient: HttpClient) {}

    getOrganisationsApi(limit: number, page: number, filter?: string[]): Observable<ListResponse<Organisation>> {
        const offset = page * limit;
        let url = `${this.url}/organisations?page[limit]=${limit}&page[offset]=${offset}`;
        if (filter && filter.length) {
            url += `&filter[id][in]=${filter.join(',')}`;
        }
        return this._httpClient.get<JsonApiResponse<any>>(url).pipe(
            map(res => {
                const data = res.data.map(organisation => new Organisation(organisation));
                const iMeta: IMeta = new Map<string, any>().set('pagination', { totalCount: res.meta.pagination.totalCount });
                return new ListResponse(data, iMeta, res.included);
            })
        );
    }
}
